const DateView = (props) => {
    const dateTime = props.date;
    if(props.date === undefined) {
        return <time>{'undefined'}</time>;
    }
    const locale = 'hu-HU';
    const month = dateTime.toLocaleString(locale, { month: 'long'});
    const day = dateTime.toLocaleString(locale, { day: '2-digit' });
    const year = dateTime.toLocaleString(locale, { year: 'numeric'})
    return <time>{year} {month} {day}</time>
}

export default DateView;