import classes from './AuthForm.module.css';
import { useState, useRef, useContext } from 'react';
import { firebaseConfig } from '../../firebase';
import AuthContext from '../../context/auth-context';
import { useNavigate } from 'react-router-dom';

const _SIGNUP_ENDPOINT = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=';
const _SIGNIN_ENDPOINT = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=';

const AuthForm = () => {
    const navigate = useNavigate();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const authCtx = useContext(AuthContext);

    const [isLogin, setIsLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const switchAuthModeHandler = () => {
        setIsLogin((prevState) => !prevState);
    };

    const submitHandler = (event) => {
        event.preventDefault();

        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        // optional add validation

        setIsLoading(true);
        if(isLogin) {
            fetch(_SIGNIN_ENDPOINT+firebaseConfig.apiKey, 
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: enteredEmail,
                        password: enteredPassword,
                        returnSecureToken: true
                    }),
                    headers: {
                        'Content-Type': 'application-json'
                    }
                }).then((res) => {
                    setIsLoading(false);
                    if(res.ok) {
                        return res.json();
                    } else {
                        return res.json().then((data) => {
                            // show an error modal
                            let errorMessage = 'Authentication failed!';
                            if(data && data.error && data.error.message) {
                                console.error(data.error.message);
                            }
                            //alert(errorMessage);
                            throw new Error(errorMessage);
                        });
                    }
                }).then((data) => {
                    const expirationTime = new Date((new Date().getTime() + (data.expiresIn * 1000)));
                    authCtx.login(data.idToken, expirationTime.toISOString());                    
                    console.log(data);
                    navigate('/');
                }).catch((err) => {
                    alert(err.message);
                })
        } else {
            fetch(_SIGNUP_ENDPOINT+firebaseConfig.apiKey, 
            {
                method: 'POST',
                body: JSON.stringify({
                    email: enteredEmail,
                    password: enteredPassword,
                    returnSecureToken: true
                }),
                headers: {
                    'Content-Type': 'application-json'
                }
            }).then((res) => {
                setIsLoading(false);
                if(res.ok) {

                } else {
                    return res.json().then((data) => {
                        // show an error modal
                        let errorMessage = 'Authentication failed!';
                        if(data && data.error && data.error.message) {
                            console.error(data);
                            errorMessage = data.error.message;
                        }
                        alert(errorMessage);
                    });
                }
            })
        }
    };

    return (
        <section id="auth-form" className="blog">
            <div className="container" data-aos="fade-up">
    
            <div className="row">
    
                <div className="col-lg-12 entries">
    

                    <section className={classes.auth}>
                    <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>
                    <form onSubmit={submitHandler}>
                        <div className={classes.control}>
                            <label htmlFor='email'>Your Email</label>
                            <input type='email' id='email' required ref={emailInputRef}/>
                        </div>
                        <div className={classes.control}>
                            <label htmlFor='password'>Your Password</label>
                            <input type='password' id='password' required ref={passwordInputRef}/>
                        </div>
                        <div className={classes.actions}>
                            {!isLoading && <button>{isLogin ? 'Login' : 'Create Account'}</button>}
                            {isLoading && <p>Sending request ...</p>}
                            {/* <button
                                type='button'
                                className={classes.toggle}
                                onClick={switchAuthModeHandler}
                            >
                                {isLogin ? 'Create new account' : 'Login with existing account'}
                            </button> */}
                        </div>
                    </form>
                    </section>
    
                </div>
    
                
    
            </div>
    
            </div>
        </section>
    )
}

export default AuthForm;