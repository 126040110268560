const HeroSection = () => {
    return (
        <section id="hero" className="d-flex align-items-center">
            <div className="container">
            <h1>Üdvözöljük Önt, kolléga!</h1>
            <h2>Csatlakozz, töltsd le és küldd vissza csatlakozási nyilatkozatot kitöltve!</h2>
            <a href="./assets/file/fmoede_csatlakozasi_nyilatkozat.pdf" className="btn-get-started scrollto" download>Csatlakozási nyilatkozat letöltése</a>
            </div>
        </section>
    )
}

export default HeroSection;