import RecentPosts from "./RecentPosts";

const Sidebar = () => {
    return (
        <div className="sidebar">
            <RecentPosts />
        </div>
    )
}

export default Sidebar;