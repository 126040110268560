import { Link } from "react-router-dom";
import DateView from "./DateView";

const PostLead = (props) => {
    return (
        <article className="entry">
    
            <div className="entry-img">
            <img src={props.post.feature_image} alt="" className="img-fluid" />
            </div>

            <h2 className="entry-title">
                <Link to={"/posts/"+props.post.id}>{props.post.title}</Link>
            </h2>

            <div className="entry-meta">
            <ul>
                {props.post.author !== "" && (<li className="d-flex align-items-center"><i className="bi bi-person"></i> 
                    <Link to={"/posts/"+props.post.id}>{props.post.author}</Link>
                </li>)}
                
                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> 
                    <Link to={"/posts/"+props.post.id}><DateView date={props.post.createdAt} /></Link>                    
                </li>
            </ul>
            </div>

            <div className="entry-content">
            <p>
                {props.post.lead}
            </p>
            <div className="read-more">
                <Link to={"/posts/"+props.post.id}>Tovább</Link>
            </div>
            </div>

        </article>
    )
}

export default PostLead;