import Header from "../components/Header";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import Main from "../components/Main";
import BreadcrumbsSection from "../components/BreadcrumbsSection";
import PostEditSection from '../components/PostEditSection';

const PostEdit = () => {
    return (
        <div>
            <TopBar />
            <Header />
            <Main>
                <BreadcrumbsSection breadcrumb={'Post Edit'} />
                <PostEditSection />
            </Main>
            <Footer />
        </div>
    )
}

export default PostEdit;