const Footer = () => {
    return (
        <footer id="footer">
            <div className="container d-md-flex py-4">

            <div className="me-md-auto text-center text-md-start">
                <div className="copyright">
                &copy; Copyright <strong><span>FMOEDE</span></strong>. Minden jog fenntartva
                </div>
                <div className="credits">
                </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
            </div>
            </div>
        </footer>
    )
}

export default Footer;