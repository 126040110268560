import { NavLink } from 'react-router-dom';
import { useContext } from "react";
import AuthContext from "../context/auth-context";

const Header = () => {
    const authCtx = useContext(AuthContext);

    return (
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center">

            <h1 className="logo me-auto"><a href="/">FMOEDE</a></h1>

            <nav id="navbar" className="navbar order-last order-lg-0">
                <ul>
                <li><NavLink activeclassname='active' className="nav-link scrollto" to="/">Főoldal</NavLink></li>
                <li><a className="nav-link scrollto" href="/#about">Rólunk</a></li>
                <li><a className="nav-link scrollto" href="/#doctors">Vezetőség</a></li>
                <li><a className="nav-link scrollto" href="/#contact">Kapcsolat</a></li>               
                <li><a className="nav-link scrollto" target="_blank" href="./assets/file/fmoede_adatkezelesi_szabalyzat.pdf">Adatvédelmi</a></li>
                {authCtx.isLoggedIn && (<li className="dropdown"><a href="#"><span>Admin</span> <i className="bi bi-chevron-down"></i></a>
                    <ul>
                        <li><NavLink activeclassname='active' to="/posts/admin">Post admin</NavLink></li>              
                        <li><NavLink activeclassname='active' to="/posts/add">Post Add</NavLink></li>              
                    </ul>
                </li>)}
                 
                <li><NavLink activeclassname='active' className="nav-link scrollto" to="/posts">Posts</NavLink></li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle"></i>
                
            </nav>

            <a href="./assets/file/fmoede_csatlakozasi_nyilatkozat.pdf" className="appointment-btn scrollto" download><span className="d-none d-md-inline"></span> Csatlakozási nyilatkozat</a>

            </div>
        </header>
    )
}

export default Header;