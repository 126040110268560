const ContactSection = () => {
    return (
        <section id="contact" className="contact">
            <div className="container">
    
            <div className="section-title">
                <h2>Kapcsolat</h2>
            </div>
            </div>
    
        
    
            <div className="container">
            <div className="row mt-5">
    
                <div className="col-lg-4">
                <div className="info">
                    <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Helység:</h4>
                    <p>5234 Tiszaroff, Aradi út 51</p>
                    </div>
    
                    <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>fmoede@protonmail.com</p>
                    </div>
    
                    <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Telefon:</h4>
                    <p>+36 70 263 1805</p>
                    </div>
    
                </div>
    
                </div>
    
                <div className="col-lg-8 mt-5 mt-lg-0">
    
                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                    <div className="row">
                    <div className="col-md-6 form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Név" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Email" required />
                    </div>
                    </div>
                    <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Tárgy" required />
                    </div>
                    <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows="5" placeholder="Szöveg" required></textarea>
                    </div>
                    <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Üzenek</button></div>
                </form>
    
                </div>
    
            </div>
    
            </div>
        </section>
    )
}

export default ContactSection;