import './Loader.css';

const Loader = (props) => {
    const message = props.message;

    return <section id="blog" className="blog">
            <div className="container">
                <h4 className="">{message}</h4>
            </div>
        </section> 
}

export default Loader;