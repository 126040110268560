import { Link } from "react-router-dom";
import PostAdminTableRow from "./PostAdminTableRow";
import PostStorageFirebase from '../context/post-storage-firebase';
import { useContext, useState, useEffect } from "react";
import Loader from "./Loader";

const PostAdminSection = () => {
    const ctx = useContext(PostStorageFirebase);
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();

    useEffect(() => {    
        ctx.fetch()
        .then((posts) => {
            setPosts(posts);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log(error)
            setIsLoading(false);
            setHttpError(error.message);
        });            
    }, []);


    let count = 0;    

    return (
        <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
    
            <div className="row">
    
                <div className="col-lg-12 entries">
                <Link to='/posts/add' className='appointment-btn'>Post Add</Link>
                </div>
            </div>
            <div className="row">    
                <div className="col-lg-12 entries">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Cím</th>
                        <th scope="col">Publikálva</th>
                        <th scope="col">Létrehozás</th>
                        <th scope="col">Műveletek</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            posts.map((post) => {
                                count = count + 1;
                                return <PostAdminTableRow post={post} key={post.id} count={count} />
                            })
                        }
                        
                       
                    </tbody>
                    </table>
                    </div>
                </div>
            </div>
    
            </div>
        </section>
    )
}

export default PostAdminSection;