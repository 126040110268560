import { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import classes from './ProfileForm.module.css';

const CHANGE_PASSWORD_ENDPOINT = "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyBDIlKBnoIMpF2ap_opjWbG3jmWDaY_j68";

const ProfileForm = () => {
    const newPasswordInputRef = useRef();
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const submitHandler = (event) => {
        event.preventDefault();

        const enteredPassword = newPasswordInputRef.current.value;

        // validation
        fetch(CHANGE_PASSWORD_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify({
                idToken: authCtx.token,
                password: enteredPassword,
                returnSecureToken: false
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            // always successds
            navigate('/');
        })
    }

    return (
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor='new-password'>Új jelszó</label>
                <input type='password' id='new-password' ref={newPasswordInputRef} />
            </div>
            <div className={classes.action}>
                <button>Jelszó megváltoztatása</button>
            </div>
        </form>
    )
}

export default ProfileForm;