import { NavLink } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/auth-context";

const TopBar = () => {
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;

    const logoutHandler = () => {
        authCtx.logout();
        // optional: redirectig the user
    }

    return (
        <div id="topbar" className="d-flex align-items-center fixed-top">
            <div className="container d-flex justify-content-between">
            <div className="contact-info d-flex align-items-center">
                <i className="bi bi-envelope"></i> 
                <a href="mailto:fmoede@protonmail.com">fmoede@protonmail.com</a>
                <i className="bi bi-envelope"></i> 
                <a href="mailto:fmoede.jelentkezes@protonmail.com">fmoede.jelentkezes@protonmail.com</a>
                <i className="bi bi-phone"></i> +36 70 263 1805
            </div>
            <div className="d-none d-lg-flex social-links align-items-center">
            {!isLoggedIn && (<NavLink to="/auth">Login</NavLink>)}
            {isLoggedIn && (<NavLink className="btn" to="/profile">Profil</NavLink>)}
            {isLoggedIn && (<button href="#" 
                    className="btn btn-primary btn-sm" 
                    onClick={logoutHandler}>
                        Logout
                    </button>)}
                
            
            </div>
            </div>
        </div>
    )
}

export default TopBar;