import Header from "../components/Header";
import TopBar from "../components/TopBar";
import HeroSection from "../components/HeroSection";
import Footer from "../components/Footer";
import Main from "../components/Main";
import WhyUsSection from '../components/WhyUsSection';
import AboutSection from '../components/AboutSection';
import DoctorsSection from "../components/DoctorsSection";
import FrequentlyAskedQuestionsSection from "../components/FrequentlyAskedQuestionsSection";
import ContactSection from "../components/ContactSection";

const Home = () => {
    return (
        <div>
            <TopBar />
            <Header />
            <HeroSection />
            <Main>
                <WhyUsSection />
                <AboutSection />
                <DoctorsSection />
                <FrequentlyAskedQuestionsSection />
                <ContactSection />
            </Main>
            <Footer />
        </div>
    )
}

export default Home;