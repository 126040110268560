import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBDIlKBnoIMpF2ap_opjWbG3jmWDaY_j68",
    authDomain: "fmoede-backend.firebaseapp.com",
    databaseURL: "https://fmoede-backend-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "fmoede-backend",
    storageBucket: "fmoede-backend.appspot.com",
    messagingSenderId: "495529406897",
    appId: "1:495529406897:web:30c51ddae2581c38ba8d90",
    measurementId: "G-DP5Y21YY3V"
  };

  const app = firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage(app);
  const database = firebase.firestore();
  const timestamp = firebase.firestore.Timestamp;

  export { storage, database, firebaseConfig, timestamp, firebase as default };