import Sidebar from "./Sidebar";
import DateView from "./DateView";
import { Link, useParams } from "react-router-dom";
import PostStorageFirebase from '../context/post-storage-firebase';
import { useEffect, useState, useContext } from "react";
import Loader from "./Loader";

// https://ckeditor.com/docs/ckeditor5/latest/features/media-embed.html

const PostDetail = () => {
    const params = useParams();
    const postId = params.postId;
   
    const ctx = useContext(PostStorageFirebase);
    const [post, setPost] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();

    useEffect(() => {    
        ctx.fetchSingle(postId)
        .then((post) => {
            setPost(post);
            setIsLoading(false);

            addFluidImage();
            embedConvertIframe();
            tableBootstrapClassAdded();
        })
        .catch((error) => {
            setIsLoading(false);
            setHttpError(error.message);
        });            
    }, []);

    const tableBootstrapClassAdded = () => {
        const editor = document.querySelector('[class="entry-content"]');
        const tables = editor.getElementsByTagName('table');
        for(const table of tables) {
            table.classList.add('table');
            table.classList.add('table-bordered');
            table.classList.add('border-primary');
        }           
    }

    const embedConvertIframe = () => {
        document.querySelectorAll( 'oembed[url]' ).forEach( element => {
            window.iframely.load( element, element.attributes.url.value );
        } );
    }

    const addFluidImage = () => {
        const elements = document.querySelector('[class="entry-content"]');
        const images = elements.getElementsByTagName('img');
        for(const image of images) {
            image.classList.add('img-fluid');
        }        
    }

    if(isLoading) {
        return <Loader message="Loading ..." />
    }

    if(httpError) {
        return <Loader message={httpError} />
    }

    return (
        <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
    
            <div className="row">
    
                <div className="col-lg-8 entries">
    
                <article className="entry entry-single">

                    <div className="entry-img">
                        <img src={post.feature_image} alt="" className= "img-fluid" />
                    </div>

                    <h2 className="entry-title">
                        <Link to={"/posts/"+post.id}>
                            {post.title}
                        </Link>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            {post.author !== "" && (<li className="d-flex align-items-center"><i className= "bi bi-person"></i> 
                                <Link to={"/posts/"+post.id}>{post.author}</Link>
                            </li>)}
                            
                            <li className="d-flex align-items-center"><i className= "bi bi-clock"></i> 
                                <Link to={"/posts/"+post.id}>
                                    <DateView date={post.createdAt} />
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <div dangerouslySetInnerHTML={{ __html: post.content}}></div>
                    </div>

                    

                </article>
                
    
                </div>
    
                <div className="col-lg-4">
    
                    <Sidebar />
    
                </div>
    
            </div>
    
            </div>
        </section>
    )
}

export default PostDetail;