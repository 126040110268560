import { storage } from '../firebase';

class MyUploadAdapter {
    constructor() {
        // CKEditor 5's FileLoader instance.
      //this.loader = props;
      // URL where to send files.
      this.url = ``;
      this.server_map = 'content_images';
    }

    setLoader(loader) {
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then((file) => {
                return new Promise((resolve, reject) => {
                    const uploadTask = storage.ref(`${this.server_map}/${file.name}`).put(file);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {},
                        error => {
                            return reject();
                        },
                        () => {
                            storage
                                .ref(this.server_map)
                                .child(file.name)
                                .getDownloadURL()
                                .then(url => {
                                    console.log(url);                                
                                    return resolve({ default:  url });
                                })
                        }
                    )
                })
            })          
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    // Example implementation using XMLHttpRequest.
    _initRequest() {
        
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject ) {
        
    }

    // Prepares the data and sends the request.
    _sendRequest() {
        
    }

}

export default new MyUploadAdapter();