const WhyUsSection = () => {
    return (
        <section id="why-us" className="why-us">
            <div className="container">
    
            <div className="row">
                <div className="col-lg-4 d-flex align-items-stretch">
                <div className="content">
                    <h3>Miért csatlakozz hozzánk?</h3>
                    <p>
                    Az egyesület fő célja az egészségügy területén dolgozó orvosok, 
                    ápolók, és egyéb egészségügyi szakdolgozók érdek-képviselete, 
                    különösképpen az egészségügyi dolgozókat ért negatív diszkrimináció
                    ellen való fellépés, elsődlegesen amely a szakmai álláspontjuk miatt éri őket.
                    </p>
                    <div className="text-center">
                    <a href="./assets/file/fmoede_csatlakozasi_nyilatkozat.pdf" className="more-btn">Csatlakozási nyilatkozat <i className="bx bx-chevron-right"></i></a>
                    </div>
                </div>
                </div>
                <div className="col-lg-8 d-flex align-items-stretch">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                    <div className="row">
                    <div className="col-xl-4 d-flex align-items-stretch">
                        <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-receipt"></i>
                        <h4>Mit kell tegyél?</h4>
                        <p>Töltsd le a csatlakozási nyilatkozatot, nyomtasd ki, 
                            kézzel tölsd ki nyomtatott nagybetűkkel, írd alá, szkenneld be és küldd el az
                            <a href="mailto:fmoede.jelentkezes@protonmail.com">fmoede.jelentkezes@protonmail.com</a> címre.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 d-flex align-items-stretch">
                        <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-cube-alt"></i>
                        <h4>Éves tagdíjak</h4>
                                <ul>
                                    <li>Orvosi tagdíj 5000 Ft/<strong>év</strong></li>
                                    <li>Szakdolgozói tagdíj 3000 Ft/<strong>év</strong></li>
                                </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 d-flex align-items-stretch">
                        <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-images"></i>
                        <h4>Adatvédelem</h4>
                        <p>Ismerd meg adatvédelmi szabályainkat!</p>
                        <p>
                            <a className="btn btn-primary" href="./assets/file/fmoede_adatkezelesi_szabalyzat.pdf" target="_blank">Adatvédelmi szabályzat</a>
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    
            </div>
        </section>
    )
}

export default WhyUsSection;