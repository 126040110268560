const DoctorsSection = () => {
    return (
        <section id="doctors" className="doctors">
        <div className="container">
  
          <div className="section-title">
            <h2>Vezetőség</h2>
          </div>
  
          <div className="row">
  
            <div className="col-lg-6">
              <div className="member d-flex align-items-start">
                <div className="member-info">
                  <h4>Dr. Matyijcsik Sándor</h4>
                  <span>Traumatológus sebész</span>
                  <p>Elnök</p>
                  <div className="social">
                  </div>
                </div>
              </div>
            </div>
  
            <div className="col-lg-6 mt-4 mt-lg-0">
              <div className="member d-flex align-items-start">
                <div className="member-info">
                  <h4>Dr. Szamos Gyöngyi</h4>
                  <span>Gyermekorvos</span>
                  <p>Alelnök</p>
                  <div className="social">
                  </div>
                </div>
              </div>
            </div>
  
            <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="member-info">
                  <h4>Könye Tímea</h4>
                  <span>Kapcsolattartó</span>
                  <p></p>
                  <div className="social">
                  </div>
                </div>
              </div>
              
            </div>
            
  
          </div>
  
        </div>
      </section>
    )
}

export default DoctorsSection;