const FrequentlyAskedQuestionsSection = () => {
    return (
        <section id="faq" className="faq section-bg">
            <div className="container">
    
            <div className="section-title">
                <h2>Gyakran ismételt kérdések</h2>
                <p></p>
            </div>
    
            <div className="faq-list">
                <ul>
                <li data-aos="fade-up">
                    <i className="bx bx-help-circle icon-help"></i> 
                    <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">
                        Orvos/egészségügyi dolgozó vagyok, hova fizethetem be a tagdíjat? 
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                    <p>
                        Utald a tagdíjat az OTP Bank 11749008-24921776 című bankszámlára, 
                        a közleménybe, ha orvos vagy írd be a neved és a pecsétszámod,
                        ha egészségügyi dolgozó akkor a nevedet.
                        Külföldről utalás esetén IBAN: HU77117490082492177600000000
                        BIC/ swift : OTPVHUHB.
                    </p>
                    </div>
                </li>
    
                <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bx bx-help-circle icon-help"></i> 
                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">
                    Mit kell tegyek a csatlakozáshoz? 
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                        <ol className="list-group list-group-numbered">
                          <li className="list-group-item">Töltsd le <span style={{display: "inline-block"}}><a style={{padding: 0}} download href="./assets/file/fmoede_csatlakozasi_nyilatkozat.pdf">innen</a></span> a csatlakozási nyilatkozatunkat</li>
                          <li className="list-group-item">Nyomtasd ki!</li>
                          <li className="list-group-item">Töltsd ki kézzel, nagy, nyomtatott betűkkel a nyomtatványt!</li>
                          <li className="list-group-item">Szkenneld be!</li>
                          <li className="list-group-item">Küld vissza nekünk, <span style={{display: "inline-block"}}><a style={{padding: 0}} href="mailto:fmoede.jelentkezes@protonmail.com">erre</a></span> az emailcímre!</li>
                        </ol>                  
                    </div>
                </li>
    
                <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bx bx-help-circle icon-help"></i> 
                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">
                    Hogyan támogathatom az egyesületet anyagilag?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                    <p>
                        Utald a támogatást az OTP Bank 11749008-24921776 bankszámlára, 
                        közleménybe írd be: "támogatás". Külföldről utalás esetén 
                        IBAN: HU77117490082492177600000000 BIC/ swift : OTPVHUHB.
                        Az egyesület adóvisszatérítésre jogosító számlát vagy igazolást
                        a befizetésről nem tud adni, illetve egyelőre 1% -os adófelajánlásokat
                        sem tudunk fogadni.
                    </p>
                    </div>
                </li>
    
                <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bx bx-help-circle icon-help"></i> 
                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">
                    Csatlakozhatok akkor is, ha már nem vagyok egészségügyi dolgozó?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                    <p>
                        Igen, az egyesület célja, hogy képviselve legyenek azok az egészségügyesek is,
                        akik elveszítették a munkájukat.
                    </p>
                    </div>
                </li>
                </ul>
            </div>
    
            </div>
        </section>
    )
}

export default FrequentlyAskedQuestionsSection;