import React from "react";
import { database } from "../firebase";

const toPost = (doc) => {
    const dbDocument = {
        id: doc.id,
        ...doc.data()
    };
    
    dbDocument.createdAt = dbDocument.createdAt.toDate();
    return dbDocument;
}


const PostStorageFirebase = React.createContext({
    update: (id, post) => {
        database.collection('posts').doc(id).update({
            title: post.title,
            lead: post.lead,
            feature_image: post.feature_image,
            content: post.content,
            publish: post.publish,
            author: ''
        });
    },


    add: async (post) => {
        try {
            await database.collection('posts').add(post);
        } catch (error) {
            console.log(error);
        }
    },

    fetchSingle: (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                database.collection('posts').doc(id).get()
                .then((doc) => {
                    if(doc.exists) {
                        const post = toPost(doc);
                        return resolve(post);
                    } else {
                        return reject(new Error('Could not find that recipe'));
                    }
                }).catch((error) => {
                    return reject(new Error(error.message));
                });
            } catch (error) {
                reject(new Error(error.message))
            }
        })
    },

    pagination: (pageSize, lastDoc) => {
        return new Promise(async (resolve, reject) => {
            try {
                let query = database.collection('posts')
                .orderBy("createdAt", "desc");
                if(lastDoc !== undefined) {
                    query = query.startAfter(lastDoc);
                }
                query = query.limit(pageSize);

                query.get().then((snapshot) => {
                    if(snapshot.empty) {
                        return reject(new Error('No post to load'));
                    } else {
                        let results = [];                        
                        snapshot.docs.forEach((doc) => {
                            const post = toPost(doc);
                            results.push(post);
                        })
                        return resolve({
                            posts: results,
                            last: snapshot.docs[snapshot.docs.length - 1]
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                    return reject(new Error(error.message));
                });                     
            } catch (error) {
                reject(new Error(error.message))
            }
          
        })             
    },

    fetch: (limit) => {
        return new Promise(async (resolve, reject) => {
            try {
                database.collection('posts')
                .orderBy("createdAt", "desc").limit(limit).get().then((snapshot) => {
                    if(snapshot.empty) {
                        return reject(new Error('No post to load'));
                    } else {
                        let results = [];                        
                        snapshot.docs.forEach((doc) => {
                            const post = toPost(doc);
                            results.push(post);
                        })
                        return resolve(results);
                    }
                }).catch((error) => {
                    return reject(new Error(error.message));
                });                     
            } catch (error) {
                reject(new Error(error.message))
            }
          
        })             
    }
});

export default PostStorageFirebase;