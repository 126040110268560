const BreadcrumbsSection = (props) => {
    return (
        <section className="breadcrumbs">
            <div className="container">
    
            <div className="d-flex justify-content-between align-items-center">
                <h2>{props.breadcrumb}</h2>
                <ol>
                <li><a href="/">Home</a></li>
                <li>{props.breadcrumb}</li>
                </ol>
            </div>
    
            </div>
        </section>
    )
}

export default BreadcrumbsSection;