import PostForm from "./PostForm";
import Sidebar from "./Sidebar";

const PostAddSection = () => {
    return (
        <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
    
            <div className="row">
    
                <div className="col-lg-8 entries">
                <PostForm />
       
                </div>
    
                <div className="col-lg-4">
    
                    <Sidebar />
    
                </div>
    
            </div>
    
            </div>
        </section>
    )
}

export default PostAddSection;