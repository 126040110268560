import PostLead from "./PostLead";
import Sidebar from "./Sidebar";
import { useEffect, useState, useContext } from "react";
import Loader from "./Loader";
import PostStorageFirebase from '../context/post-storage-firebase';
import { ScrollSpy } from 'bootstrap';

const PostSection = () => {
    const ctx = useContext(PostStorageFirebase);
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const [pageSize, setPageSize] = useState(2);
    const [lastDoc, setLastDoc] = useState(0);
    const [showPaginator, setShowPaginator] = useState(true);

    useEffect(() => {    
        ctx.pagination(pageSize)
        .then((response) => {
            setPosts(response.posts);
            setIsLoading(false);
            setLastDoc(response.last);
            setLast();
        })
        .catch((error) => {
            setIsLoading(false);
            setHttpError(error.message);
        });            
    }, []);

    const paginationHandler = () => {
        ctx.pagination(pageSize, lastDoc)
        .then((response) => {
            console.log(response);
            setPosts([...posts, ...response.posts]);
            setIsLoading(false);
            setLastDoc(response.last);
            setLast();
            if(response.posts.length < pageSize) {
                setShowPaginator(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    }

    const setLast = () => {
        const entries = document.querySelector('.entries');
        const articles = entries.querySelectorAll('article');
        for(const article of articles) {
            article.id = "";
        }
        const lastArticle = articles[articles.length -1];
        lastArticle.id = 'last';        
    }

    if(isLoading) {
        return <Loader message="Loading ..." />
    }

    if(httpError) {
        return <Loader message={httpError} />
    }

    return (
        <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
    
            <div className="row">
    
                <div className="col-lg-8 entries">
    
                {posts.map(post => <PostLead post={post} key={post.id} />)}

    
                {showPaginator && (<div className="blog-pagination">
                    <ul className="justify-content-center">
                        <li>                            
                            <button className="btn btn-primary" onClick={paginationHandler}>
                                További posztok betöltése
                            </button>
                        </li>                    
                    </ul>
                </div>)}
                
    
                </div>
    
                <div className="col-lg-4">
    
                    <Sidebar />
    
                </div>
    
            </div>
    
            </div>
        </section>
    )
}

export default PostSection;