import PostEditForm from "./PostEditForm";
import Sidebar from "./Sidebar";
import { useParams } from "react-router-dom";
import PostStorageFirebase from '../context/post-storage-firebase';
import { useContext, useState, useEffect } from "react";
import Loader from "./Loader";

const PostEditSection = () => {
    const params = useParams();
    const postId = params.postId;
    const ctx = useContext(PostStorageFirebase);
    const [post, setPost] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();

    useEffect(() => {    
        ctx.fetchSingle(postId)
        .then((post) => {
            setPost(post);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            setHttpError(error.message);
        });            
    }, []);

    if(isLoading) {
        return <Loader message="Loading ..." />
    }

    if(httpError) {
        return <Loader message={httpError} />
    }
    
    return (
        <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
    
            <div className="row">
    
                <div className="col-lg-8 entries">
                <PostEditForm post={post} />
       
                </div>
    
                <div className="col-lg-4">
    
                    <Sidebar />
    
                </div>
    
            </div>
    
            </div>
        </section>
    )
}

export default PostEditSection;