import logo from './logo.svg';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Posts from './pages/Posts';
import PostSingle from './pages/PostSingle';
import Auth from './pages/Auth';
import Profile from './pages/Profile';
import PostAdd from './pages/PostAdd';
import PostAdmin from './pages/PostAdmin';
import PostEdit from './pages/PostEdit';
import AuthContext, { AuthContextProvider } from './context/auth-context';
import { useContext } from 'react';

// build out of memory solution
// set NODE_OPTIONS=--max_old_space_size=8192

// https://firebase.google.com/docs/reference/rest/auth

function App() {
  const authCtx = useContext(AuthContext);

  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:postId" element={<PostSingle />} />
        {authCtx.isLoggedIn && (<Route path="/posts/add" element={<PostAdd />} />)}
        {authCtx.isLoggedIn && (<Route path="/posts/edit/:postId" element={<PostEdit />} />)}
        {authCtx.isLoggedIn && (<Route path="/posts/admin" element={<PostAdmin />} />)}
        {authCtx.isLoggedIn && (<Route path="/profile" element={<Profile />} />)}
        <Route path="/auth" element={<Auth />} />        
        <Route path="*" element={<Home />} />
      </Routes>          
  );
}

export default App;
