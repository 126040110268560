import RecentPostItem from "./RecentPostItem";
import PostStorageFirebase from '../context/post-storage-firebase';
import { useContext, useState, useEffect } from "react";
import Loader from "./Loader";

const RecentPosts = () => {
    //const posts = props.posts;
    const ctx = useContext(PostStorageFirebase);
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();

    useEffect(() => {    
        ctx.fetch(6)
        .then((posts) => {
            setPosts(posts);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            setHttpError(error.message);
        });            
    }, []);

    if(isLoading) {
        return <Loader message="Loading ..." />
    }

    if(httpError) {
        return <Loader message={httpError} />
    }


    return (
        <div>
            <h3 className="sidebar-title">Recent Posts</h3>
            <div className="sidebar-item recent-posts">
            {posts.map(post => <RecentPostItem post={post} key={post.id} />)}

            </div>
        </div>
    )
}

export default RecentPosts;