import { Link } from "react-router-dom";
import DateView from "./DateView";

const PostAdminTableRow = (props) => {
    const post = props.post;

    return (
        <tr>
            <th scope="row">{props.count}</th>
            <td>{post.title}</td>
            <td>{post.publish ? 'Igen' : 'Nem'}</td>
            <td>
                <DateView date={post.createdAt} />
            </td>
            <td>
                <Link  to={`/posts/edit/${post.id}`} className={'btn btn-success'}>Szerkesztés</Link>
                &nbsp;&nbsp;
                <Link  to={`/posts/${post.id}`} className={'btn btn-primary'}>Megnyitás</Link>
            </td>
        </tr>
    )
}

export default PostAdminTableRow;