import { Link } from "react-router-dom";
import DateView from "./DateView";

const RecentPostItem = (props) => {
    const post = props.post;

    return (
        <div className="post-item clearfix">
            <img src={post.feature_image} alt="" />
            <h4>
                <Link to={"/posts/"+post.id}>
                    {post.title}
                </Link>
            </h4>
            <DateView date={post.createdAt} />
        </div>
    )
}

export default RecentPostItem;