import { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './PostForm.css';
import { storage, timestamp } from '../firebase';
import PostStorageFirebase from '../context/post-storage-firebase';
import MyUploadAdapter from '../others/MyUploadAdapter';

// ckeditor 5
// https://morioh.com/p/359d2563af46
// https://medium.com/swlh/ckeditor5-with-custom-image-uploader-on-react-67b4496cb07d
// https://youtu.be/ju2e4wIkfB0
// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';




const PostForm = () => {
    const ctx = useContext(PostStorageFirebase);
    const navigate = useNavigate();

    const [formIsValid, setFormIsValid] = useState(true);

    const [enteredTitle, setEnteredTitle] = useState('');
    const [enteredTitleIsValid, setEnteredTitleIsValid] = useState(false);
    const [enteredTitleTouched, setEnteredTitleTouched] = useState(false);

    const [enteredLead, setEnteredLead] = useState('');
    const [enteredLeadIsValid, setEnteredLeadIsValid] = useState(false);
    const [enteredLeadTouched, setEnteredLeadTouched] = useState(false);

    const [enteredImage, setEnteredImage] = useState(null);
    const [enteredImageUrl, setEnteredImageUrl] = useState('');
    const [enteredImageIsValid, setEnteredImageIsValid] = useState(false);
    const [enteredImageTouched, setEnteredImageTouched] = useState(false);
    const [progress, setProgress] = useState(0);
    
    const [enteredContent, setEnteredContent] = useState('');
    const [enteredContentIsValid, setEnteredContentIsValid] = useState(false);
    const [enteredContentTouched, setEnteredContentTouched] = useState(false);
    
    const [enteredPublish, setEnteredPublish] = useState(false);

    const titleInputChangeHandler = event => {
        setEnteredTitle(event.target.value);
        setEnteredTitleTouched(true);
        setEnteredTitleIsValid(true);
    };

    const titleInputBlurHandler = (event) => {
        setEnteredTitleTouched(true);
        if(enteredTitle.trim() === '') {
            setEnteredTitleIsValid(false);
            return;
        }
    };

    const leadInputChangeHandler = event => {
        setEnteredLead(event.target.value);
        setEnteredLeadTouched(true);
        setEnteredLeadIsValid(true);
        if(enteredLead.trim().length > 399) {
            setEnteredLeadIsValid(false);
            return;
        }
    };

    const leadInputBlurHandler = event => {
        setEnteredLeadTouched(true);
        if(enteredLead.trim() === '') {
            setEnteredLeadIsValid(false);
            return;
        }

    }

    const imageInputChangeHandler = event => {
        setEnteredImageTouched(true);
        if(event.target.files[0]) {
            setEnteredImage(event.target.files[0]);
            setEnteredImageIsValid(true);
        }
    }    

    const imageInputBlurHandler = event => {
        setEnteredImageTouched(true);
        if(enteredImageUrl.length === 0) {
            setEnteredImageIsValid(false);
            return;
        }
    }

    const contentInputChangeHandler = event => {
        setEnteredContent(event);
        setEnteredContentTouched(true);
        setEnteredContentIsValid(true);
    };

    const contentInputBlurHandler = event => {
        setEnteredContentTouched(true);
        if(enteredContent.trim() === '') {
            setEnteredContentIsValid(false);
            return;
        }
    }

    const publishInputChangeHandler = event => {
        setEnteredPublish(event.target.checked);
    };

    const formSubmissionHandler = event => {
        event.preventDefault();

        setEnteredTitleTouched(true);
        setEnteredContentTouched(true);
        setEnteredLeadTouched(true);

        if(enteredTitle.trim() === '') {
            setEnteredTitleIsValid(false);
            setFormIsValid(false);
            return;
        }
        if(enteredTitle.trim().length > 75) {
            setEnteredTitleIsValid(false);
            return;
        }
        setEnteredTitleIsValid(true);

        if(enteredLead.trim() === '') {
            setEnteredLeadIsValid(false);
            return;
        }
        if(enteredLead.trim().length > 399) {
            setEnteredLeadIsValid(false);
            return;
        }
        setEnteredLeadIsValid(true);
        
        if(enteredImageUrl.length === 0) {
            setEnteredImageIsValid(false);
            return;
        }
        setEnteredImageIsValid(true);

        if(enteredContent.trim() === '') {
            setEnteredContentIsValid(false);
            return;
        }
        setEnteredContentIsValid(true);
      
        const newPost = {
            title: enteredTitle,
            lead: enteredLead,
            feature_image: enteredImageUrl,
            content: enteredContent,
            publish: enteredPublish,
            author: '',
            createdAt: nowDate()
        };

        ctx.add(newPost);

        setEnteredTitle('');
        setEnteredContent('');
        setEnteredLead('');
        setEnteredPublish(false);
        setEnteredImageUrl('');
        setEnteredImage(null);

        console.log('created new post');
        console.log(newPost);
        // http send post

        navigate(`/posts`);
    }

    const nowDate = () => {
        return timestamp.fromDate(new Date());
        // const now = new Date();
        // const month = now.toLocaleString('hu-HU', { month: '2-digit'});
        // const day = now.toLocaleString('hu-HU', { day: '2-digit' });
        // const hour = now.toLocaleString('hu-HU', { hour: '2-digit' });
        // const minute = now.toLocaleString('hu-HU', { minute: '2-digit' });
        // const second = now.toLocaleString('hu-HU', { second: '2-digit' });
        // const year = now.getFullYear();
        // return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }

    const cancelHandler = () => {
        navigate(`/posts/admin`);
    }

    const titleInputIsValid = !enteredTitleIsValid && enteredTitleTouched;
    const titleInputClasses = titleInputIsValid ? 'is-invalid' : '';

    const leadInputIsValid = !enteredLeadIsValid && enteredLeadTouched;
    const leadInputClasses = leadInputIsValid ? 'is-invalid' : '';    

    const contentInputIsValid = !enteredContentIsValid && enteredContentTouched;
    const contentInputClasses = contentInputIsValid ? 'is-invalid' : '';

    const imageInputIsValid = enteredImageIsValid && enteredImageTouched;
    const imageInputClasses = !imageInputIsValid ? '' : 'is-invalid';

    const imageUploadHandler = () => {
        const uploadTask = storage.ref(`feature_images/${enteredImage.name}`).put(enteredImage);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref("feature_images")
                    .child(enteredImage.name)
                    .getDownloadURL()
                    .then(url => {
                        console.log(url);
                        setEnteredImageUrl(url);
                    })
            }
        )
    }

    const custom_config = {        
        extraPlugins: [ MyCustomUploadAdapterPlugin ],
        toolbar: {
            items: [
                'heading',
                '|',
                'undo',
                'redo',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'blockQuote',
                '|',
                'imageUpload',
                'mediaEmbed',
                'insertTable'
                
            ]
            },
            table: {
                contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
            },            
        }
    
        function MyCustomUploadAdapterPlugin(editor) {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
                MyUploadAdapter.setLoader(loader);
                return MyUploadAdapter;
              }
        }    

    return (
        <article className="entry entry-single">

        <form onSubmit={formSubmissionHandler} method="post" role="form" className="needs-validation php-email-form">
            <div className="form-group mt-3">
                <input 
                    value={enteredTitle} 
                    onChange={titleInputChangeHandler}
                    onBlur={titleInputBlurHandler}
                    type="text" 
                    className={`form-control ${titleInputClasses}`} 
                    name="title" 
                    id="title" 
                    placeholder="Cím" 
                />
                {!enteredTitleIsValid && <div className="invalid-feedback">Címet meg kell adni!</div>}
            </div>
            <div className="form-group mt-3">
                <textarea 
                    className={`form-control ${leadInputClasses}`} 
                    onChange={leadInputChangeHandler}
                    onBlur={leadInputBlurHandler}
                    value={enteredLead}
                    name="lead" 
                    id="lead" 
                    rows="3" 
                    placeholder="Lead"
                    >
                </textarea>
                {!enteredLeadIsValid && <div className="invalid-feedback">Bevezetőt meg kell adni</div>}
            </div>

            <div className="form-group mt-3">
            <label htmlFor="feature_image" className="form-label">Feature image</label>
            <div className="input-group">
                <input 
                    onChange={imageInputChangeHandler}
                    onBlur={imageInputBlurHandler}
                    type="file" 
                    name="feature_image" 
                    id="feature_image" 
                    placeholder="Feature image" 
                    className={`form-control ${imageInputClasses}`} 
                    aria-describedby="inputGroupFileAddon04" 
                    aria-label="Upload" 
                />
                <button 
                    className="btn btn-outline-secondary" 
                    type="button" 
                    id="inputGroupFileAddon04"
                    onClick={imageUploadHandler}
                >
                    Feltöltés
                </button>
                {!enteredImageIsValid && <div className="invalid-feedback">Képet fel kell tölteni.</div>}
                
            </div>
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{width: `${progress}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                </div>
            </div>
            {enteredImageUrl.length > 0 && <img src={enteredImageUrl} alt='firebase-image' className='img-fluid' />}

            <div className="form-group mt-3">
                <h6>Tartalom</h6>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ custom_config }
                    data={enteredContent}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        setEnteredContent(editor.getData());
                        //console.log( { event, editor, data } );
                    }}                                   
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
                {enteredContentIsValid && enteredContentTouched && <div className="invalid-feedback-tinymce-editor">Tartalmat meg kell adni</div>}

            </div>
                       
            <div className="form-check">
                <input 
                    checked={enteredPublish} 
                    onChange={publishInputChangeHandler} 
                    className="form-check-input" 
                    type="checkbox" 
                    name="publish" 
                    id="publish" 
                    />
                <label className="form-check-label" htmlFor="publish">
                    Publish
            </label>
            </div>
            <div className="my-3">
                {/* <div className="loading">Loading</div> */}
                {/* <div className="error-message">sfsfsfsefsef</div> */}
                {/* <div className="sent-message">Your message has been sent. Thank you!</div> */}
            </div>
            <div className="text-center">
                <button type="submit" className="btn appointment-btn">Beküldés</button>
                <button onClick={cancelHandler} className="btn appointment-btn">Cancel</button>
            </div>
        </form>
            

        </article>
    )
}

export default PostForm;