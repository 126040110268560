const AboutSection = () => {
    return (
        <section id="about" className="about">
            <div className="container-fluid">
    
            <div className="row">
                <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
                
                </div>
    
                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                <h3>Független Magyar Orvosi és Egészségügyi Dolgozói Egyesület</h3>
                <p>Az egyesület fő célja az egészségügy területén dolgozó orvosok, ápolók, és egyéb egészségügyi
                    szakdolgozók érdek-képviselete, különösképpen az egészségügyi dolgozókat ért negatív diszkrimináció
                    ellen való fellépés, elsődlegesen amely a szakmai álláspontjuk miatt éri őket.
                </p>
    
                <div className="icon-box">
                    <div className="icon"><i className="bx bx-atom"></i></div>
                    <p className="description">Célunk, hogy ne lehessen megfosztani a munkájától, egészségügyi tevékenysége
                    gyakorlásától valakit, csak mert szakmai álláspontja máséval nem egyezik meg mindenben.
                    </p>
                </div>
    
                <div className="icon-box">
                    <div className="icon"><i className="bx bx-atom"></i></div>
                    <p className="description">Céljaink közé tartozik a hátrányos helyzetbe került egészségügyi dolgozók
                    megsegítése, érdekeik képviselete.
                    </p>
                </div>
    
                <div className="icon-box">
                    <div className="icon"><i className="bx bx-atom"></i></div>
                    <p className="description">
                    Az egyesület célja a diszkriminációmentes egészségügyi társadalom és diszkriminációmentes egészségügyi
                    munkahelyek elérése Magyarországon, a megkülönböztetés-mentes és igazságos egészségügyi környezet
                    meghatározása és érvényesítése.
                    </p>
                </div>
    
                <div className="icon-box">
                    <div className="icon"><i className="bx bx-atom"></i></div>
                    <p className="description">
                    Célunk, hogy kizárólag publikált és ellenőrzött tényeken alapuló tudományos tények
                    érvényesülhessenek az egészségügy és a gyógyítás területén.
                    </p>
                </div>
    
                </div>
            </div>
    
            </div>
        </section>
    )
}

export default AboutSection;