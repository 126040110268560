import Header from "../components/Header";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import Main from "../components/Main";
import BreadcrumbsSection from "../components/BreadcrumbsSection";
import PostDetail from "../components/PostDetail";

const PostSingle = () => {
    return (
        <div>
            <TopBar />
            <Header />
            <Main>
                <BreadcrumbsSection breadcrumb={'Post'} />
                <PostDetail />
            </Main>
            <Footer />
        </div>
    )
}

export default PostSingle;